import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import {md3} from "vuetify/blueprints";

const theme = {
  dark: false,
  colors: {
    primary: '#01AAC1',
    secondary: '#00D6C2'
  }
}

const vuetify = createVuetify({
  theme: {
    defaultTheme: 'theme',
    themes: {theme},
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  blueprint : md3
})

export default vuetify
