export function useSetPageTitle(title?: string) : void {
  document.title = title ? `${title} | Secure Clinic Help` : 'Secure Clinic Help';
}

export function useSetPageDescription(description?: string): void {
  let element = document.querySelector('meta[name="description"]')
  if (!element && !description) {
    return
  }
  if (element && !description) {
    element.remove()
    return;
  }
  if (!element) {
    element = document.createElement("meta")
    element.setAttribute('name', 'description')
    document.head.appendChild(element)
  }
  if (description && element) {
    element.setAttribute('content', description)
  }
}
