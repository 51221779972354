<template>
  <v-app>
    <v-app-bar color="primary">
      <v-row class="align-center justify-center">
        <v-col cols="6" class="mt-1">
          <router-link to="/">
            <img src="/logo_header.svg" alt="Secure Clinic" height="60" class="my-2">
          </router-link>
        </v-col>
        <v-col cols="6">
          <h1 class="text-h5 text-right mr-2">Help & Support</h1>
        </v-col>
      </v-row>

    </v-app-bar>

    <v-main>
      <v-row>
        <v-col cols="12" md="10" offset-md="1">
          <router-view class="pa-2"/>
        </v-col>
      </v-row>
    </v-main>

    <v-footer color="secondary" class="flex-column mt-2">
      <p class="text-center mt-8 text-white">
        <a href="https://secure.clinic/">
          <img src="/logo_header.svg" alt="Secure Clinic Ltd." width="200" class="mb-0"/>
        </a>
        <p class="text-caption mt-0">
          UK Limited Company 15050934<br/>
          71-75 Shelton Street, Covent Garden, London
        </p>
      </p>
      <div class="text-subtitle-2 mt-4">
        <a href="https://secure.clinic/" class="text-decoration-none text-white">Main Website</a>
        &nbsp;
        <a href="#" class="cky-banner-element text-white text-decoration-none mt-2">Cookie Settings</a>
      </div>
    </v-footer>
  </v-app>

</template>

<script lang="ts" setup>

</script>
