import { createRouter, createWebHistory } from 'vue-router'
import {nextTick} from "vue";
import NotFound from "@/views/NotFound.vue";
import {useSetPageTitle, useSetPageDescription} from "@/helper";
import NProgress from 'nprogress'
import '../assets/progress.css'



//Manual sitemap is in use
//public.sitemap.xml
const routes = [
  {
    path: '/',
    component: () => import('@/views/Home.vue'),
    name: 'Home',
    meta: {
      title: 'Home',
      description: 'Help and support for the Secure Clinic Cloud Electronic Patient Record'
    }
  },
  {
    path: '/categories/:category',
    name: 'Category',
    component: () => import('@/views/Category.vue'),
    meta: {
      viewManagesTitle: true,
      viewManagesDescription: true
    }
  },
  {
    path: '/articles/:article',
    name: 'Article',
    component: () => import('@/views/Article.vue'),
    meta: {
      viewManagesTitle: true,
      viewManagesDescription: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: {title: "Not Found"}
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, behavior: "smooth"}
    }
  },
  routes
})

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  NProgress.done()
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
    if (typeof to.meta.title === 'string'){
      useSetPageTitle(to.meta.title)
    } else if(!to.meta.viewManagesTitle){
      useSetPageTitle()
    }

    if (typeof to.meta.description === "string") {
      useSetPageDescription(to.meta.description)
    } else if(!to.meta.viewManagesDescription) {
      useSetPageDescription();
    }
  })
});

export default router
